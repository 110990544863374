import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

import Hero from '../components/hero'
import Preamble from '../components/homepage/sectionPreamble'
import OurServices from '../components/homepage/sectionOurServices'
import Price from '../components/homepage/sectionPrice'
import Faq from '../components/homepage/sectionFaq'
import Cta from '../components/homepage/sectionCta'

const IndexPage = () => (
  <Layout>
    <Seo title="Недорогие сайты на WordPress от EnotWeb" />

    <Hero />
    <Preamble />
    <OurServices />
    <Price
      title='Цены на сайты и услуги'
      description='Мы постарались сделать для тебя максимально выгодную стоимость.'
    />
    <Faq />
    <Cta />

  </Layout>
)

export default IndexPage
