import React from 'react'

/* This example requires Tailwind CSS v2.0+ */
import { Disclosure } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/outline'

const faqs = [
  {
    question: "Как быстро я получу работающий сайт?",
    answer:
      "Работающий сайт ты получишь в течение 1-2 дней. Тебе останется только наполнить его своим контентом.",
  },
	{
    question: "Что мне делать, если возникнут вопросы по управлению сайтом?",
    answer:
      "Сайт создан на базе WordPress. Этот движок сайта имеет простую и понятную панель управления. Кроме того он очень популярен и в Интернете много понятной информации обо всем, что касается WordPress. Поэтому в большинстве случаев будет легко разобраться самостоятельно или посмотрев подходящий видос на Youtube. Ну, и конечно, ты можешь получить консультацию у нас за небольшие деньги.",
  },
  {
    question: "Почему такая низкая цена за сайт?",
    answer:
      "В этом нашем проекте так и задумано - быстрый старт за доступные деньги. Мы собрали самый минимум необходимый для работающего сайта и использовали готовые решения: проверенный и удобный движок WordPress, готовый оптимизированный шаблон, надежный хостинг. Это все разворачивается быстро и без дополнительных затрат, поэтому стоимость вполне доступная. При этом ты должен понимать: чтобы сайт развивался и приносил больше и больше дохода, в него нужно будет вкладывать и время и деньги.",
  },
	{
    question: "Мне нужен нестандартный сайт, вы сможете мне помочь?",
    answer:
      "Мы умеем делать почти любые сайты, если ты об этом :). Но в рамках данного проекта мы предлагаем только недорогие готовые сайты. За твой большой и сложный проект мы не возьмемся, прости. Но может тебе вполне подойдет готовый сайт, такое бывает. Чтобы определиться, пожалуйста обращайся, проконсультируем за небольшие деньги.",
  },
	{
    question: "Какие гарантии вы предоставляете?",
    answer:
      "Мы гарантируем качетвенное выполнение всех работ. Гарантируем то, что все домены, хостинг, шаблоны регистрируются на тебя, все доступы передаются тебе и ты получаешь полный контроль над своим сайтом.",
  },
  // More questions...
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function sectionFaq() {
  return (
    <div className="bg-gray-50">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto divide-y-2 divide-gray-200">
          <h2 className="text-center text-3xl font-extrabold text-gray-900 sm:text-4xl">Вопросы и ответы</h2>
          <dl className="mt-6 space-y-6 divide-y divide-gray-200">
            {faqs.map((faq) => (
              <Disclosure as="div" key={faq.question} className="pt-6">
                {({ open }) => (
                  <>
                    <dt className="text-lg">
                      <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
                        <span className="font-medium text-gray-900">{faq.question}</span>
                        <span className="ml-6 h-7 flex items-center">
                          <ChevronDownIcon
                            className={classNames(open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform')}
                            aria-hidden="true"
                          />
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base text-gray-500">{faq.answer}</p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}
